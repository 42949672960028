import * as React from "react";

import Layout from "../components/Layout";

import hero_img from "../assets/img/hero/pc.png";

import axios from "axios";
import { format } from "date-fns";
import _ from "lodash";

const OrderConfirmation = () => {
  const [quotation, setQuotation] = React.useState({});

  let IndianRupee = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
  });

  React.useEffect(() => {
    // get order_id from url
    const urlParams = new URLSearchParams(window.location.search);
    const order_id = urlParams.get("order_id");

    axios
      .get("https://api.computerhut.club/api/v1/orders/order-confirmation/" + order_id)
      .then((response) => {
        setQuotation(response.data.data);
      });
  }, []);

  const formatDate = (date) => {
    return format(new Date(date), "dd MMMM yyyy, hh:mm a");
  };

  return (
    <Layout>
      <section className="confirmation-section">
        <div className="container">
          <div className="content">
            {_.isEmpty(quotation) ? (
              <></>
            ) : (
              <div className="quotation-builder-second-phase">
                <h3>
                  {quotation.quotation.name}, here's your finalised Order
                  Confirmation
                </h3>
                <p>
                  Your order has been successfully placed. We'll get in touch
                  with you shortly to confirm the order and start building your
                  PC. In the meantime, you can reach out to us at <a href="tel:+91">hello</a>
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <div className="pc">
                      <img src={hero_img} alt="hero" />
                    </div>
                  </div>
                  <div className="col-md-6 content-wp">
                    <div className="content">
                      <p>
                        <strong>Order ID:</strong> {quotation.order_id}
                      </p>
                      <p>
                        <strong>Order Date:</strong>{" "}
                        {formatDate(quotation.createdAt)}
                      </p>
                      <p>
                        <strong>Total Amount:</strong>{" "}
                        {IndianRupee.format(quotation.quotation.total_price)}
                      </p>
                      <p>
                        <strong>Amount Paid:</strong>{" "}
                        {IndianRupee.format(quotation.total_amt)}
                      </p>
                      <p>
                        <strong>Amount Pending:</strong>{" "}
                        {IndianRupee.format(
                          quotation.quotation.total_price - quotation.total_amt
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OrderConfirmation;
